import React, {useEffect, useMemo, useRef, useState} from 'react';
import {ChevronDownIcon, XMarkIcon} from '@heroicons/react/24/solid'
import './SignUpValidators'
import {validateForm} from "./SignUpValidators";
import './SignUpModal.component.css'
import {useTranslation} from "react-i18next";
import axiosInstance from '../../interceptor/Interceptor';
import { notification } from 'antd';


function SignUpModal({closeModal}) {
	const {t, i18n} = useTranslation('translation', {keyPrefix: 'signUpModal'})
	document.onkeydown = (evt) => {
		if (evt.key === 'Escape') {
			closeModal()
		}
	};

	const [roleSelectOpened, setRoleSelectOpened] = useState(false);
	const roleList = t('roleList', {returnObjects: true})

	const [selectedName, setSelectedName] = useState('');
	const [nameDirty, setNameDirty] = useState(false);

	const [selectedSurname, setSelectedSurname] = useState('');
	const [surnameDirty, setSurnameDirty] = useState(false);

	const [selectedPhone, setSelectedPhone] = useState('');
	const [phoneDirty, setPhoneDirty] = useState(false);

	const [selectedEmail, setSelectedEmail] = useState('');
	const [emailDirty, setEmailDirty] = useState(false);

	const [selectedRole, setSelectedRole] = useState(roleList[0]);

	const [fieldErrors, setFieldErrors] = useState({
		name: [],
		surname: [],
		phone: [],
		email: []
	});

	const submitButtonRef = useRef();
	useEffect(() => {
		submitButtonRef.current?.scrollIntoView({behavior: 'smooth'})
	}, [roleSelectOpened]);

	useEffect(() => {
		validateFields()
	}, [selectedName, selectedSurname, selectedPhone, selectedEmail]);

	const validateFields = () => {
		let errors = validateForm(selectedName, selectedSurname, selectedPhone, selectedEmail, t('validation', {returnObjects: true}));
		setFieldErrors(errors)
		let numberOfErrors = 0
		Object.values(errors).forEach(errorArray => numberOfErrors += errorArray.length);
		return numberOfErrors
	}

	const handleSubmit = () => {
		setNameDirty(true)
		setSurnameDirty(true)
		setPhoneDirty(true)
		setEmailDirty(true)
		const numberOfErrors = validateFields()
		if (numberOfErrors === 0) {
			const formData = {
				name: selectedName,
				surname: selectedSurname,
				phone: selectedPhone,
				email: selectedEmail,
				role: selectedRole
			}
			// todo отправить объект с данными куда-нибудь
			axiosInstance.post(`https://api.sheetbest.com/sheets/7213d9a6-d5f5-4386-8854-fa2a9b5821cd`, formData).then((res) => {
				if (res.status == 200){
					closeModal();
					notification["success"]({
						message: "Вы успешно оставили заявку"
					})
				}
			})
		}
	}

	return (
		<div
			className={`fixed inset-0 bg-black/40 backdrop-blur-xl z-[1000] cursor-pointer`}
			onClick={closeModal}
		>
			<div
				className={`py-4 max-h-full rounded-[15px] fixed  left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-default md:w-[35em] md:px-0 px-4 w-full select-none flex flex-col gap-1`}
				onClick={(evt) => {
					evt.preventDefault()
					evt.stopPropagation()
					evt.nativeEvent.stopImmediatePropagation()
				}}>
				<div
					className={`no-scrollbar bg-white relative rounded-[15px] gap-4 overflow-y-auto overscroll-contain shadow-md flex flex-col md:p-8 p-4 text-left`}>
					<XMarkIcon
						className={`md:hidden block absolute top-4 right-4 size-8 text-black/50 active:rounded-full active:ring-black/50 active:ring-2 cursor-pointer`}
						onClick={closeModal}/>
					<span className={`font-bold text-[24px] md:text-[32px] text-center`}>{t('title')}</span>
					<form method={`post`} className={`flex flex-col gap-4`} autoComplete="off">
						<div className={`flex flex-col gap-1`}>
							<label htmlFor="" className={`text-[16px]`}>{t('name')}</label>
							<input aria-autocomplete="list" type="text" id={``} placeholder={t('namePlaceholder')}
										 value={selectedName}
										 onChange={(evt) => {
											 setTimeout(() => setNameDirty(true), 100)
											 setSelectedName(evt.target.value)
										 }}
										 className={`shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light md:px-6 px-4 md:py-4 py-2 rounded-[9px] border border-[#ADADAD]`}/>
							<ul
								className={`${nameDirty && fieldErrors.name.length > 0 ? 'block' : 'hidden'} flex flex-col gap-1 text-red-600 text-[14px]`}>
								{fieldErrors.name.map((error, idx) => (
									<li key={idx}>{error}</li>
								))}
							</ul>
						</div>
						<div className={`flex flex-col gap-1`}>
							<label htmlFor="" className={`text-[16px]`}>{t('surname')}</label>
							<input aria-autocomplete="list" type="text" id={``} placeholder={t('surnamePlaceholder')}
										 value={selectedSurname}
										 onChange={(evt) => {
											 setTimeout(() => setSurnameDirty(true), 100)
											 setSelectedSurname(evt.target.value)
										 }}
										 className={` shadow-none outline-none focus:border-[#4285F4]  placeholder:text-[14px] placeholder:font-light font-light md:px-6 px-4 md:py-4 py-2 rounded-[9px] border border-[#ADADAD]`}/>
							<ul
								className={`${surnameDirty && fieldErrors.surname.length > 0 ? 'block' : 'hidden'} flex flex-col gap-1 text-red-600 text-[14px]`}>
								{fieldErrors.surname.map((error, idx) => (
									<li key={idx}>{error}</li>
								))}
							</ul>
						</div>
						<div className={`flex flex-col gap-1`}>
							<label htmlFor="" className={`text-[16px]`}>{t('phone')}</label>
							<input aria-autocomplete="list" type="tel" id={``} placeholder={`+7 ...`}
										 value={selectedPhone.replace(/[^0-9+\s-]/g, '')}
										 onChange={(evt) => {
											 setTimeout(() => setPhoneDirty(true), 100)
											 setSelectedPhone(evt.target.value.replace(/[^0-9+\s-]/g, ''))
										 }}
										 className={` shadow-none outline-none focus:border-[#4285F4]  placeholder:text-[14px] placeholder:font-light font-light md:px-6 px-4 md:py-4 py-2 rounded-[9px] border border-[#ADADAD]`}/>
							<ul
								className={`${phoneDirty && fieldErrors.phone.length > 0 ? 'block' : 'hidden'} flex flex-col gap-1 text-red-600 text-[14px]`}>
								{fieldErrors.phone.map((error, idx) => (
									<li key={idx}>{error}</li>
								))}
							</ul>
						</div>
						<div className={`flex flex-col gap-1`}>
							<label htmlFor="" className={`text-[16px]`}>{t('email')}</label>
							<input aria-autocomplete="list" type="email" id={``} placeholder={`user@mail.ru`}
										 value={selectedEmail}
										 onChange={(evt) => {
											 setTimeout(() => setEmailDirty(true), 100)
											 setSelectedEmail(evt.target.value)
										 }}
										 className={` shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light md:px-6 px-4 md:py-4 py-2 rounded-[9px] border border-[#ADADAD]`}/>
							<ul
								className={`${emailDirty && fieldErrors.email.length > 0 ? 'block' : 'hidden'} flex flex-col gap-1 text-red-600 text-[14px]`}>
								{fieldErrors.email.map((error, idx) => (
									<li key={idx}>{error}</li>
								))}
							</ul>
						</div>
						<div className={`flex flex-col gap-1`}>
							<label htmlFor="" className={`text-[16px]`}>{t('role')}</label>
							<div
								onClick={() => setRoleSelectOpened(!roleSelectOpened)}
								className={`h-full focus:border-[#4285F4] font-light rounded-[9px] border border-[#ADADAD] cursor-pointer flex flex-col items-center`}>
								<div className={`md:px-6 px-4 md:py-4 py-2 flex flex-row justify-between w-full`}>
									<span className={`${roleSelectOpened && 'text-[#B6B6B6]'}`}>{selectedRole}</span>
									<ChevronDownIcon
										className={`text-[#767676] size-6 transition-transform  ${roleSelectOpened && 'rotate-180'}`}/>
								</div>
								<div
									className={`flex-col w-full overflow-hidden ${roleSelectOpened ? 'flex' : 'hidden'}`}>
									{roleList.map((role, key) => (
										<span
											className={`block md:px-6 px-4 md:py-4 py-2 duration-100 hover:border-l-4 active:border-l-8 border-[#393DA1] transition-all ease-in`}
											key={key} onClick={() => setSelectedRole(role)}>{role}</span>
									))}
								</div>
							</div>
						</div>
					</form>
					<button
						ref={submitButtonRef}
						onClick={handleSubmit}
						className={`font-bold text-[16px] bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors w-full rounded-[10px] shadow-[0px_4px_19px_0px_rgba(119,147,65,0.3)] text-white py-3`}>
						{t('submit')}
					</button>
				</div>
				<span
					className={`text-white text-[14px]`}>{t('escape')}</span>
			</div>
		</div>
	)
		;
}

export default SignUpModal;
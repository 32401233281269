import React, {useEffect, useState} from 'react';
import '../../../assets/css/main.css';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/24/solid';
import {useNavigate} from 'react-router-dom';
import {validateStudentForm} from "./ProfileValidators";
import Container from "../../Container";
import {useDispatch} from "react-redux";
import {registerStudent} from "../../../actions/newStudentActions";
import Loading from '../../Loading';


const NewStudent = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [sphereSelectOpened, setSphereSelectOpened] = useState(false);

    const [grade, setGrade] = useState('');
    const [gradeDirty, setGradeDirty] = useState(false);

    const [name, setName] = useState('');
    const [nameDirty, setNameDirty] = useState(false);

    const [school, setSchool] = useState('');

    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dateOfBirthDirty, setDateOfBirthDirty] = useState(false);

    const [photo, setPhoto] = useState(null);

    const [spheres, setSpheres] = useState({
        "Бизнес и управление": 1,
        "Здравоохранение": 1,
        "Образование": 1,
        "Естественные науки, математика и статистика": 1,
        "Инженерия": 1,
        "ИТ": 1,
        "Социальные науки": 1,
        "Гуманитарные науки": 1,
        "Искусство": 1,
        "Услуги": 1,
        "Сельское хозяйство и биоресурсы": 1,
        "Национальная оборона и безопасность": 1
    });
    
    const [loading, setLoading] = useState(false);

    const changeSphereValue = (eValue, key) => {
        let newSpheres = {...spheres}
        if (eValue === '') {
            newSpheres[key] = 1
            setSpheres(newSpheres)
            return
        }
        if (eValue.length === 2 && eValue[0] === '1') {
            newSpheres[key] = Number(eValue[1])
            setSpheres(newSpheres)
        }
        if (parseInt(eValue) >= 0 && parseInt(eValue) <= 10) {
            newSpheres[key] = parseInt(eValue)
            setSpheres(newSpheres)
        }
    }

    useEffect(() => {
        if (!sphereSelectOpened) {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
    }, [sphereSelectOpened]);

    const [fieldErrors, setFieldErrors] = useState({
        grade: [],
        name: [],
        dateOfBirth: []
    });

    const validateFields = () => {
        let errors = validateStudentForm(grade, name, dateOfBirth);
        setFieldErrors(errors)
        let numberOfErrors = 0
        Object.values(errors).forEach(errorArray => numberOfErrors += errorArray.length);
        return numberOfErrors
    }
    useEffect(() => {
        validateFields()
    }, [grade, name, dateOfBirth]);


    const handleSubmit = () => {
        setLoading(true);
        setGradeDirty(true)
        setNameDirty(true)
        setDateOfBirthDirty(true)
        const numberOfErrors = validateFields()
        if (numberOfErrors === 0) {
            const formData = new FormData()
            formData.append('School', school);
            formData.append('Name', name);
            formData.append('Grade', grade);
            formData.append('Date_of_birth', dateOfBirth);
            formData.append('proInput', JSON.stringify(spheres));
            if (photo) {
                formData.append('photo', photo);
            }
            dispatch(registerStudent(formData))
                .then((result) => {
                    navigate(`/upload/${result._id}`);
                    setLoading(false);
                })
                .catch((err) => {
                });
        }
    }

    return (
        <div className="bg-[#eaf8fc] min-h-screen">
            { loading && <Loading isLoading={loading}/>}
            <Container>
                <div className="pb-4 rounded-[15px] lg:max-w-[40em] w-full select-none mx-auto">
                    <div className="no-scrollbar bg-white relative rounded-[15px] gap-4 shadow-md flex flex-col md:p-6 p-3 text-left">
                        <span className="font-bold text-[24px] md:text-[32px] text-center">Информация о вас</span>
                        <form className="flex flex-col gap-4" autoComplete="off">
                            <div className="flex flex-col gap-1">
                                <label htmlFor="school" className="text-[16px]">Школа</label>
                                <input
                                    type="text"
                                    id="school"
                                    placeholder="Введите название школы"
                                    value={school}
                                    onChange={(evt) => setSchool(evt.target.value)}
                                    className="shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]"
                                />
                            </div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="" className="text-[16px]">Класс</label>
                                <input aria-autocomplete="list" type="text" placeholder='Введите класс'
                                       value={grade}
                                       onChange={(evt) => {
                                           setTimeout(() => setGradeDirty(true), 100)
                                           setGrade(evt.target.value)
                                       }}
                                       className="shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]"/>
                                <ul
                                    className={`${gradeDirty && fieldErrors.grade.length > 0 ? 'block' : 'hidden'} flex flex-col gap-1 text-red-600 text-[14px]`}>
                                    {fieldErrors.grade.map((error, idx) => (
                                        <li key={idx}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="" className="text-[16px]">Имя</label>
                                <input aria-autocomplete="list" type="text" placeholder='Введите имя и фамилию'
                                       value={name}
                                       onChange={(evt) => {
                                           setTimeout(() => setNameDirty(true), 100)
                                           setName(evt.target.value)
                                       }}
                                       className="shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]"/>
                                <ul
                                    className={`${nameDirty && fieldErrors.name.length > 0 ? 'block' : 'hidden'} flex flex-col gap-1 text-red-600 text-[14px]`}>
                                    {fieldErrors.name.map((error, idx) => (
                                        <li key={idx}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="" className="text-[16px]">Дата рождения</label>
                                <input aria-autocomplete="list" type="date" placeholder='Введите дату рождения'
                                       value={dateOfBirth}
                                       onChange={(evt) => {
                                           setTimeout(() => setDateOfBirthDirty(true), 100)
                                           setDateOfBirth(evt.target.value)
                                       }}
                                       className="shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]"/>
                                <ul
                                    className={`${dateOfBirthDirty && fieldErrors.dateOfBirth.length > 0 ? 'block' : 'hidden'} flex flex-col gap-1 text-red-600 text-[14px]`}>
                                    {fieldErrors.dateOfBirth.map((error, idx) => (
                                        <li key={idx}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="" className="text-[16px]">Фото</label>
                                <div className="flex flex-row gap-4 justify-between items-center w-full">
                                    <input aria-autocomplete="list"
                                           accept="image/*"
                                           type="file"
                                           onChange={e => setPhoto(e.target.files[0])}
                                           className="shadow-none outline-none focus:border-[#4285F4] font-light px-4 py-3 rounded-[9px] border border-[#ADADAD] w-full"/>
                                    {photo &&
                                        <div
                                            className="size-[3em] min-h-[3em] min-w-[3em] rounded-full overflow-hidden ring-[#ADADAD] ring-1">
                                            <img src={photo ? URL.createObjectURL(photo) : null} alt="" className="size-full object-cover"/>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="" className="text-[16px]">Оцените, насколько эти сферы подходят вам</label>
                                <div
                                    className="h-full focus:border-[#4285F4] font-light rounded-[9px] border border-[#ADADAD] flex flex-col items-center">
                                    <div
                                        className={`px-4 py-3 flex flex-row justify-between w-full cursor-pointer rounded-[9px] text-[#767676]
                                        ${sphereSelectOpened ? 'bg-white' : 'bg-[#F7F7F7]'}`}
                                        onClick={() => setSphereSelectOpened(!sphereSelectOpened)}>
                                        <span>Показать сферы</span>
                                        <ChevronDownIcon
                                            className={`text-[#767676] size-6 transition-transform  ${sphereSelectOpened && 'rotate-180'}`}/>
                                    </div>
                                    <div
                                        className={`flex-col gap-2 w-full py-2 ${sphereSelectOpened ? 'flex' : 'hidden'}`}>
                                        {Object.keys(spheres).map((sphereKey, idx) => (
                                            <div
                                                className="px-4 flex flex-row justify-between items-center"
                                                key={idx}>
                                                <div>{sphereKey}</div>
                                                <input type="text"
                                                       className="outline-none max-w-[4em] px-[18px] py-[10px] ring-[#B9B9B9] ring-[1px] rounded-[5px]
                                                       text-[#767676] text-[16px] font-[500] text-center"
                                                       placeholder={'0-10'}
                                                       value={Object.values(spheres)[idx]}
                                                       onChange={e => changeSphereValue(e.target.value, sphereKey)}/>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </form>
                        <button
                            onClick={handleSubmit}
                            disabled={loading}
                            className="font-bold text-[16px] bg-[#1AAD7F] hover:bg-[#1AAD7F]/80 transition-colors w-full rounded-[10px] text-white py-3">
                            Сохранить
                        </button>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default NewStudent;

import axios from "axios";
import {API_URL} from "./constants";
import axiosInstance from "../interceptor/Interceptor";

const editStudent = (userData) => {
	return axiosInstance
		.patch(API_URL + `Gallup/${userData.user_id}/edit_info`, userData, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
		.then((response) => {
			return response.data;
		});
};

const editSpecialist = (userData) => {
	return axiosInstance
		.patch(API_URL + `Gallup/${userData.user_id}/edit_business_info`, userData, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
		.then((response) => {
			return response.data;
		});
};

export default {editStudent, editSpecialist};
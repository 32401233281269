import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTestResults } from '../../../actions/testResultActions';
import '../../../assets/css/main.css';

import introvertsImage from '../../../assets/images/introverts.svg';
import extrovertsImage from '../../../assets/images/extroverts.svg';
import observantsImage from '../../../assets/images/observants.svg';
import intuitivesImage from '../../../assets/images/intuitives.svg';
import thinkingsImage from '../../../assets/images/thinkings.svg';
import sentimentalsImage from '../../../assets/images/sentimentals.svg';
import judgingsImage from '../../../assets/images/judgings.svg';
import seekersImage from '../../../assets/images/seekers.svg';
import enfjImage from '../../../assets/images/enfj-protagonist.svg';
import enfpImage from '../../../assets/images/enfp-campaigner.svg';
import entjImage from '../../../assets/images/entj-commander.svg';
import entpImage from '../../../assets/images/entp-debater.svg';
import esfjImage from '../../../assets/images/esfj-consul.svg';
import esfpImage from '../../../assets/images/esfp-entertainer.svg';
import estjImage from '../../../assets/images/estj-executive.svg';
import estpImage from '../../../assets/images/estp-entrepreneur.svg';
import infjImage from '../../../assets/images/infj-advocate.svg';
import infpImage from '../../../assets/images/infp-mediator.svg';
import intjImage from '../../../assets/images/intj-architect.svg';
import intpImage from '../../../assets/images/intp-logician.svg';
import isfjImage from '../../../assets/images/isfj-defender.svg';
import isfpImage from '../../../assets/images/isfp-adventurer.svg';
import istjImage from '../../../assets/images/istj-logistician.svg';
import istpImage from '../../../assets/images/istp-virtuoso.svg';

const MBTI = () => {
    const dispatch = useDispatch();
    const { user_id } = useParams();
    const navigate = useNavigate();
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const testResults = useSelector(state => state.testResult.testResults);

    const [mbti, setMbti] = useState("");
    const [mbtiTranslation, setMbtiTranslation] = useState("");

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login');
        } else {
            dispatch(fetchTestResults(user_id));
        }
    }, [dispatch, isLoggedIn, navigate, user_id]);

    useEffect(() => {
        if (testResults) {
            if (testResults.MBTI) {
                setMbti(testResults.MBTI);
            }
            if (testResults.MBTI_translation) {
                setMbtiTranslation(testResults.MBTI_translation);
            }
        }
    }, [testResults]);

    const mbtiTypes = {
        INTJ: {
            image: intjImage,
            description: "На вершине одиноко. Стратеги это хорошо знают — это один из самых редких и стратегически одаренных типов личностей. Стратеги составляют всего два процента населения, а женщины с таким типом личности — это особенная редкость — всего 0,8 % населения. Им часто тяжело найти единомышленников, которые смогут поддержать их неустанный интеллект и их мышление шахматиста. Люди с типом личности Стратега одарены богатым воображением и одновременно решительны, амбициозны, но скрытны, чрезвычайно любопытны, но не тратят попусту свою энергию."
        },
        INTP: {
            image: intpImage,
            description: "Тип личности «Ученый» встречается довольно редко и составляет лишь три процента населения: это очень хорошо, ибо нет для таких людей нет большей неприятности, чем быть «как все». Ученые гордятся своей изобретательностью и творческой натурой, уникальным видением и живым интеллектом. Такой человек может быть известным философом, архитектором или эксцентричным профессором. Ученым принадлежат многие научные открытия в нашей истории."
        },
        ENTJ: {
            image: entjImage,
            description: "Командиры — это прирожденные лидеры. Люди с этим типом личности объединяют в себе харизму и уверенность, они обладают авторитетом, который собирает толпы вокруг одной общей цели. В отличие от более чувствительных Протагонистов Командиры характеризуются часто безжалостным уровнем рациональности и направляют свою энергию, уверенность и острый ум на достижение запланированных целей. Вероятно, хорошо, что такие люди составляют всего три процента населения, не затмевая собой более робких и чувствительных типов личности, которые составляют остальное население мира, однако нам следует поблагодарить Командиров за многие компании и учреждения, услугами которых мы пользуемся каждый день."
        },
        ENTP: {
            image: entpImage,
            description: "Полемист — величайший адвокат дьявола, который успешно разрушает все аргументы и мнения на мелкие составляющие, пуская их по ветру, чтобы все видели. В отличие от более целеустремленных личностей Полемисты делают это все не потому, что пытаются достичь какой-то стратегической задачи или глубинной цели, а просто для удовольствия. Никто так не обожает процесс ментального поединка, как Полемисты, поскольку это дает им возможность потренировать свой исключительно острый ум, широкую базу познаний и способность объединять разрозненные идеи в доказательства своей правоты."
        },
        INFJ: {
            image: infjImage,
            description: "Активист — очень редкий тип личности. Активисты составляют менее одного процента населения, но оставляют яркий след в мире. У них врожденное чувство идеализма и моральности, а от других идеалистов их отличает решительность и целеустремленность. Активисты — это не ленивые мечтатели, а люди, которые способны предпринимать конкретные шаги для достижения своих целей и выполнения долгосрочной стратегии."
        },
        INFP: {
            image: infpImage,
            description: "Посредники — истинные идеалисты, всегда ищущие искру добра даже в худших из людей или событий в попытке улучшить ситуацию. В то время, как многие воспринимают их как спокойных, сдержанных, даже стеснительных людей, в Посредниках горит внутреннее пламя и страсть, которые могут в какой-то момент засиять. Составляя всего 4% от населения, личности типа «Посредник» часто рискуют остаться непонятыми — но, когда они находят думающих схожим образом людей, с которыми они проводят время, появившаяся гармония становится источником радости и вдохновения."
        },
        ENFJ: {
            image: enfjImage,
            description: "Тренеры являются природными лидерами, полными страсти и харизмы. Составляя всего около двух процентов населения, эти люди встречаются среди наших политиков, наших тренеров, наших наставников, они распространяют влияние и вдохновляют остальных на достижения во имя улучшения мира. Тренеры от природы уверены в себе и оказывают влияние на людей, они с гордостью и радостью демонстрируют другим, как улучшить самого себя и мир вокруг."
        },
        ENFP: {
            image: enfpImage,
            description: "Борец — настоящий свободный дух. Борцы часто являются заводилами на вечеринках, однако они не сильно заинтересованы в шумихе и не склонны к наслаждению моментом, они наслаждаются социальными и эмоциональными контактами с другими. Очаровательные, независимые, энергичные и сострадательные — те 7 % населения, которые они составляют, можно, безусловно, почувствовать в любой толпе."
        },
        ISTJ: {
            image: istjImage,
            description: "Тип личности Администратор считается одним из наиболее распространенных и составляет 13% населения. Определяющие характеристики данного типа — целостность, практическая логика и неустанное следование своему долгу — делают Администраторов жизненно важным ядром многих семей и организаций, в которых ценятся традиции, правила и стандарты, например, в юридических компаниях, правительственных организациях или в армии. Люди типа личности Администратор предпочитают отвечать за свои поступки и гордиться работой, которую они выполняют — когда они работают над поставленной задачей, они не жалеют ни сил, ни энергии, выполняя задания аккуратно и терпеливо."
        },
        ISFJ: {
            image: isfjImage,
            description: "Тип личности Защитник весьма уникален, поскольку многие из их способностей противоречат их индивидуальным чертам. Чуткие Защитники могут прийти в ярость, если необходимо защитить свою семью или друзей; спокойные и скрытные, они, как правило, обладают хорошо развитыми навыками общения с людьми и крепких социальных взаимоотношений; и хотя они стремятся к безопасности и стабильности, такие типы личности могут быть весьма открытыми к изменениям, если чувствуют, что их понимают и уважают. Как и большинство подобных явлений, люди типа Защитник есть нечто большее, чем сумма их частей, и то, как они используют свои сильные стороны, определяет их личность."
        },
        ESTJ: {
            image: estjImage,
            description: "Менеджеры представляют закон и порядок, они применяют свое понимание того, что правильно и неправильно, что приемлемо в обществе, а что нет. Они скрепляют семьи и сообщества. Твердо веря в честность, прилежность и порядочность, людей с типом личности Менеджера ценят за четкие советы и разумные инструкции, и они с удовольствием ведут за собой через сложные препятствия. Гордясь своей способностью объединять людей, Менеджеры часто становятся общественными организаторами, упорно работающими, собирающими людей на важные общественные праздники либо на защиту традиционных ценностей, скрепляющих семьи и общество."
        },
        ESFJ: {
            image: esfjImage,
            description: "Люди, относящиеся к типу личности «Консул», являются, за неимением более подходящего слова, популярными, ведь такой тип личности составляет двенадцать процентов населения. В старших классах Консулы — это чирлидеры и квотербэки, которые задают тон, находятся в центре внимания и ведут свои команды к победе и славе. Позже по жизни Консулы продолжают пользоваться поддержкой своих друзей и любимых, организовывая социальные встречи и делая все возможное для того, чтобы убедиться, что все счастливы."
        },
        ISTP: {
            image: istpImage,
            description: "Виртуозы любят пробовать новое на ощупь и на глаз, трогая и изучая мир вокруг себя со спокойным рационализмом и воодушевленным любопытством. Люди с таким типом личности — прирожденные творцы, двигающиеся от проекта к проекту, строя полезное и бесполезное ради самого процесса и приобретая новый опыт по мере продвижения. Часто они бывают механиками или инженерами: для Виртуозов нет большего удовольствия, чем возиться по локоть в механизмах, разбирая и собирая их вновь, делая их чуть лучше, чем они были до этого."
        },
        ISFP: {
            image: isfpImage,
            description: "Артисты являются настоящими творческими личностями, но не обязательно в стандартном значении этого слова, подразумевающем, что они рисуют маленьких счастливых пони. Да, такое бывает нередко, они прекрасно это могут. Скорее это означает, что они своим пониманием эстетики и дизайна и собственными действиями раздвигают границы общепризнанных норм. Артисты любят разрушать традиционные ожидания экспериментами с внешним видом и поведением.Вероятно, у них голове постоянно звучит: «Не сажайте меня в клетку!»"
        },
        ESTP: {
            image: estpImage,
            description: "Тип личности Делец всегда оказывает влияние на свое непосредственное окружение; лучший способ обнаружить их на вечеринке — это следить за водоворотом людей, следующим за кем-то, кто перемещается от группы к группе. Смешливые и артистичные, с грубоватым и житейским чувством юмора, Дельцы любят быть центром внимания. Если просят кого-то из публики выйти на сцену, Делец вызовется первым или подтолкнет застенчивого друга."
        },
        ESFP: {
            image: esfpImage,
            description: "Если кто-то и может спонтанно пуститься в пляс и запеть, так это тип личности Развлекатель. Развлекатели живут восторгом момента, и хотят, чтобы и все остальные чувствовали себя так же. Никакой другой тип личности не отдает настолько щедро свое время и силы, когда нужно приободрить других, и никакой другой тип личности не делает это настолько стильно и неотразимо."
        }
    };

    const mbtiLettersDescriptions = {
        I: {
            image: introvertsImage,
            title: "I - Интроверты",
            description: "предпочитают одиночные занятия и устают от социальных взаимодействий. Они, как правило, довольно чувствительны к внешним стимулам (например, звукам, зрительным или обонятельным раздражителям)."
        },
        E: {
            image: extrovertsImage,
            title: "E - Экстраверты",
            description: "предпочитают групповые занятия и получают энергию от социальных взаимодействий. Они, как правило, более энтузиастичны и легче возбуждаются, чем интроверты."
        },
        S: {
            image: observantsImage,
            title: "S - Наблюдательные",
            description: "Наблюдательные люди очень практичны, прагматичны и приземлены. Они склонны к сильным привычкам и сосредоточены на том, что происходит или уже произошло."
        },
        N: {
            image: intuitivesImage,
            title: "N - Интуитивные",
            description: "Интуитивные люди очень воображаемы, открыты и любознательны. Они предпочитают новизну стабильности и сосредоточены на скрытых значениях и будущих возможностях."
        },
        T: {
            image: thinkingsImage,
            title: "T - Думающие",
            description: "Думающие люди сосредотачиваются на объективности и рациональности, отдавая предпочтение логике над эмоциями. Они склонны скрывать свои чувства и считают эффективность более важной, чем сотрудничество."
        },
        F: {
            image: sentimentalsImage,
            title: "F - Чувствующие",
            description: "Чувствующие люди чувствительны и эмоционально выразительны. Они более эмпатичны и менее конкурентоспособны, чем думающие типы, и сосредоточены на социальной гармонии и сотрудничестве."
        },
        J: {
            image: judgingsImage,
            title: "J - Судящие",
            description: "Судящие люди решительны, тщательны и очень организованы. Они ценят ясность, предсказуемость и завершенность, предпочитая структуру и планирование спонтанности."
        },
        P: {
            image: seekersImage,
            title: "P - Ищущие",
            description: "Ищущие люди очень хорошо импровизируют и видят возможности. Они, как правило, гибки, расслаблены и неконформисты, предпочитающие держать свои варианты открытыми."
        },
    };

    return (
        <div className={`flex flex-col gap-8 w-full`}>
            <div className='flex flex-col items-center mb-8'>
                <div className='text-[34px] font-bold text-black'>
                    {mbti}
                </div>
                <div className='text-[30px] text-gray-600'>
                    {mbtiTranslation}
                </div>
            </div>
			
            {mbtiTypes[mbti] && (
                <div className='flex flex-col items-center mb-8'>
                    <img src={mbtiTypes[mbti].image} className='object-cover h-56 mb-4' alt={`${mbti} image`} />
                    <div className='text-[20px] text-center text-justify'>
                        {mbtiTypes[mbti].description}
                    </div>
                </div>
            )}
			 <div className='border-b border-gray-300 mb-4'></div>
            {mbti.split('').map(letter => (
                mbtiLettersDescriptions[letter] && (
                    <div key={letter} className='flex justify-center items-center xl:flex-row flex-col gap-8'>
                        <div className='xl:hidden sm:text-[24px] sm:leading-[28.2px] text-[16px] leading-[18.8px] font-bold text-black'>
                            {mbtiLettersDescriptions[letter].title}
                        </div>
                        <div className="flex flex-col gap-2 sm:min-w-[40%] sm:max-w-[40%] max-w-[165px]">
                            <div className='w-full'>
                                <img src={mbtiLettersDescriptions[letter].image} className={`object-cover h-full`} />
                            </div>
                        </div>
                        <div className='xl:w-full flex flex-col gap-4 text-start'>
                            <div className='xl:block hidden sm:text-[24px] sm:leading-[28.2px] text-[16px] leading-[18.8px] font-bold text-black'>
                                {mbtiLettersDescriptions[letter].title}
                            </div>
                            <div className='sm:text-[20px] sm:leading-[28px] text-[14px] leading-[16.45px] text-[#3B3B3B] font-medium text-justify'>
                                {mbtiLettersDescriptions[letter].description}
                            </div>
                        </div>
                    </div>
                )
            ))}
        </div>
    );
}

export default MBTI;
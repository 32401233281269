import React, { useState, useEffect } from 'react';
import '../../../assets/css/main.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { fetchReports } from '../../../actions/reportActions';
import { fetchGallupById } from '../../../actions/gallupActions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../layout/Loader";

const ReportForm = () => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'dashboard.userCard' });
  const { reportId, gallupId } = useParams();

  const [gallupUrl, setGallupUrl] = useState('');
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [nextReportId, setNextReportId] = useState(2);
  const [prevReportId, setPrevReportId] = useState(1);
  const [isBusiness, setIsBusiness] = useState(false);
  const [showRegenerateButton, setShowRegenerateButton] = useState(false); //
  const [numPages, setNumPages] = useState(null);
  const [isInIframe, setIsInIframe] = useState(false);

  useEffect(() => {
      setIsInIframe(window.self !== window.top);
  }, []);


  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const loading = useSelector(state => state.report.loading);
  const reports = useSelector(state => state.report.reports);
  const error = useSelector(state => state.report.error);
  const gallupData = useSelector(state => state.gallup.gallupObject);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mobileDevice = window.innerWidth < 500;

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    } else {
      dispatch(fetchGallupById(gallupId));
    }
  }, [gallupId, isLoggedIn, navigate, dispatch]);

  useEffect(() => {
    if (gallupData) {
      setIsBusiness(gallupData.Business);
      setShowRegenerateButton(false);
      dispatch(fetchReports(gallupId, reportId, gallupData.Business)).then(() => {
        setShowRegenerateButton(true); // Show button after receiving response
      });
    }
  }, [gallupData, gallupId, reportId, dispatch]);

  useEffect(() => {
    setNextReportId(parseInt(reportId) + 1);
    setPrevReportId(parseInt(reportId) - 1);
  }, [reportId]);

  useEffect(() => {
    if (reports && error === null) {
      setGallupUrl(`${reports}?timestamp=${new Date().getTime()}`);
    }
  }, [reports, error]);

  const regenerateReport = () => {
    setShowRegenerateButton(false); // Hide button during regeneration
    dispatch(fetchReports(gallupId, reportId, isBusiness, true)).then(() => {
      setShowRegenerateButton(true); // Show button after receiving response
    });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className="bg-[#eaf8fc] min-h-screen">
      <div className="container-dashboard mx-auto flex flex-col xl:flex-col gap-4 pb-4">
        <div className='flex md:justify-between flex-wrap md:flex-nowrap align-center justify-center gap-3 md:gap-none'>
          {prevReportId >= 1 ? (
            <button
              className='bg-[#393DA1] p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white '
              onClick={() => {
                if (prevReportId === 4) {
                  navigate(`/spheres/${gallupId}`);
                } else {
                  navigate(`/reports/${prevReportId}/${gallupId}`);
                }
                setPrevReportId(prevReportId - 1);
              }}
            >
              {'Предыдущий отчет'}
            </button>
          ) : (
            <button
              className='bg-[#393DA1] p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'
              onClick={() => {
                navigate(`/profile/${gallupId}`);
                setPrevReportId(prevReportId - 1);
              }}
            >
              Вернуться назад
            </button>
          )}

          {showRegenerateButton && (
            <button
              className='bg-green-800 p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'
              onClick={regenerateReport}
            >
              Сгенерировать заново
            </button>
          )}

          {nextReportId <= 4 ? (
            <button
              className='bg-[#393DA1] p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'
              onClick={() => {
                navigate(`/reports/${nextReportId}/${gallupId}`);
                setNextReportId(nextReportId + 1);
              }}
            >
              Следующий отчет
            </button>
          ) : nextReportId === 5 ? (
            <button
              className='bg-[#393DA1] p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'
              onClick={() => {
                navigate(`/spheres_new/${gallupId}`);
              }}
            >
              Страница сфер
            </button>
          ) : nextReportId <= 6 ? (
            <button
              className='bg-[#393DA1] p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'
              onClick={() => {
                navigate(`/reports/${nextReportId}/${gallupId}`);
                setNextReportId(nextReportId + 1);
              }}
            >
              Следующий отчет
            </button>
          ) : (
            <button
              className='bg-[#393DA1] p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'
              onClick={() => {
                navigate(`/profile/${gallupId}`);
              }}
            >
              Завершить сеанс
            </button>
          )}
        </div>
        {loading ? <Loader /> : (
          // <>
          //   <object
          //     data={gallupUrl}
          //     type="application/pdf"
          //     width="100%"
          //     height="820px"
          //     onLoad={() => setPdfLoaded(true)}
          //     onError={(e) => {
          //       console.error("Ошибка загрузки PDF:", e);
          //       // Обработка ошибки при загрузке PDF
          //     }}
          //   >
          //     <div>
          //       <p>Для получения отчета:</p>
          //       <p>1. Попробуйте перезагрузить страницу.</p>
          //       <p>2. Попробуйте нажать на эту <Link to={gallupUrl} target="_blank" rel="noopener noreferrer" style={{ color: '#FF5733' }}> ссылку </Link> что бы загрузить отчет.</p>
          //       <p>3. Так же можно будет скачать отчет в профиле ученика.</p>
          //       <p>4. Проверьте загруженные файлы и загрузите профиль ученика заново.</p>
          //       <p>5. Если проблема останется нерешенной, пожалуйста, обратитесь к администратору.</p>
          //     </div>
          //   </object>
          // </>
            (!isInIframe) && (
              <iframe 
                  src={gallupUrl} 
                  width="100%" 
                  height={mobileDevice ? '300px' : '800px'} 
                />
            )
        )}
      </div>
    </div>
  );
}

export default ReportForm;

import axios from "axios";
import {API_URL} from "./constants";
import axiosInstance from "../interceptor/Interceptor";

const fetchTestResults = (gallupId) => {
	return axiosInstance
		.get(API_URL + `Gallup/${gallupId}/test_results`, {
			headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`},
		})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export default {
	fetchTestResults
};

import Loading from '../Loading';
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/outline";
import { registerUser } from '../../actions/authActions';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const { t } = useTranslation('translation', {keyPrefix: 'login'})
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [registerData, setRegisterData] = useState({
    email: '',
    password: '',
    keyword: ''
  })
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [keywordHidden, setKeywordHidden] = useState(true);
  const [loading, setLoading] = useState(false);

  const registerUserHandler = () => {
    if (registerData.email && registerData.password && registerData.keyword){
        setLoading(true);
        dispatch(registerUser(registerData)).then((res) => {
            if (res.status === 201){
                navigate('/login');
                notification["success"]({
                    message: "Вы успешно создали аккаунт"
                })
            }
        }).catch(error => {
            if (error.status == 400){
                notification["error"]({
                    message: error.detail
                })
            }
        }).finally(() => {
            setLoading(false);
        })
    } else { 
        notification["warning"]({
            message: "Заполните все поля"
        })
    }
  };    

  return (
    <div className='container mx-auto'>
        { loading && <Loading isLoading={loading} /> }
        <div className='mb-3 font-bold text-[40px]'>
            Регистрация
        </div>
        <div className='mb-3 flex flex-col items-stretch text-left w-[90%] md:w-[50%] mx-auto'>
            <span className='text-[16px]'>{t('enterLogin')}</span>
            <input
                className='mt-2 rounded-[9px] ring-[#ADADAD] ring-1 focus:ring-[#4285F4] py-3 text-[14px] px-4'
                type='text'
                placeholder={t('loginPlaceholder')}
                value={registerData.email}
                onChange={(e) => setRegisterData({ ...registerData, email: e.target.value })}
            />
        </div>
        <div className='flex flex-col text-left w-[90%] md:w-[50%] mx-auto mb-3'>
            <span className='text-[16px]'>{t('enterPassword')}</span>
            <div className="relative">
                <div className="relative flex rounded-lg shadow-sm">
                    <input type={passwordHidden ? 'password' : 'text'}
                        value={registerData.password}
                        onChange={(e) => setRegisterData({ ...registerData, password: e.target.value })}
                        className="text-[14px] mt-2 py-3 px-4 pe-11 w-full rounded-[9px] ring-[#ADADAD] ring-1 focus:ring-[#4285F4]"
                        placeholder={t('passwordPlaceholder')}/>
                    <button onClick={(e) => {
                        e.preventDefault()
                        setPasswordHidden(!passwordHidden);
                    }}
                                    className="mt-2 absolute inset-y-0 end-0 flex items-center z-20 pe-4">
                        <EyeIcon
                            className={`size-6 text-[#8E8E8E] ${!passwordHidden && 'hidden'}`}></EyeIcon>
                        <EyeSlashIcon
                            className={`size-6 text-[#8E8E8E] ${passwordHidden && 'hidden'}`}></EyeSlashIcon>
                    </button>
                </div>
            </div>
        </div>
        <div className='flex flex-col text-left w-[90%] md:w-[50%] mx-auto mb-3'>
            <span className='text-[16px]'>{t('enterKeyword')}</span>
            <div className="relative">
                <div className="relative flex rounded-lg shadow-sm">
                    <input type={keywordHidden ? 'password' : 'text'}
                        value={registerData.keyword}
                        onChange={(e) => setRegisterData({ ...registerData, keyword: e.target.value })}
                        className="text-[14px] mt-2 py-3 px-4 pe-11 w-full rounded-[9px] ring-[#ADADAD] ring-1 focus:ring-[#4285F4]"
                        placeholder={t('passwordPlaceholder')}/>
                    <button onClick={(e) => {
                        e.preventDefault()
                        setKeywordHidden(!keywordHidden);
                    }}
                                    className="mt-2 absolute inset-y-0 end-0 flex items-center z-20 pe-4">
                        <EyeIcon
                            className={`size-6 text-[#8E8E8E] ${!keywordHidden && 'hidden'}`}></EyeIcon>
                        <EyeSlashIcon
                            className={`size-6 text-[#8E8E8E] ${keywordHidden && 'hidden'}`}></EyeSlashIcon>
                    </button>
                </div>
            </div>
        </div>
        <button className="w-[90%] md:w-[50%] mb-5 text-white disabled:cursor-not-allowed bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors rounded-md py-2" type="button" onClick={registerUserHandler} disabled={loading}>Регистрация</button>
    </div>
  )
}

export default Register
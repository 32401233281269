import adminService from '../services/admin.service';
import { FETCH_USERS_FAILURE, FETCH_USERS_STARTED, FETCH_USERS_SUCCESS } from './actionTypes/adminActionTypes';

export const fetchUsersList = () => (dispatch) => {
    dispatch({
        type: FETCH_USERS_STARTED
    });

    return adminService.fetchUsers().then(
        (data) => {
            dispatch({
                type: FETCH_USERS_SUCCESS,
                users: data
            })

            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: FETCH_USERS_FAILURE,
                error: error.response.data
            })

            return Promise.reject(error);
        }
    )
}

export const updateUsersToAdmincc = (body) => (dispatch) => {
    return adminService.updateUsersToAdmincc(body).then(
        (data) => {
            return Promise.resolve(data);
        },
        (error) => {
            return Promise.reject(error);
        }
    )
}

export const updateUsersToTanuAdmin = (body) => (dispatch) => {
    return adminService.updateUsersToTanuAdmin(body).then(
        (data) => {
            return Promise.resolve(data);
        },
        (error) => {
            return Promise.reject(error);
        }
    )
}

export const updateUsersToProforientator = (body) => (dispatch) => {
    return adminService.updateUsersToProforientator(body).then(
        (data) => {
            return Promise.resolve(data);
        },
        (error) => {
            return Promise.reject(error);
        }
    )
}

export const addUsersToCc = (body) => (dispatch) => {
    return adminService.addUsersToCc(body).then(
        (data) => {
            return Promise.resolve(data);
        },
        (error) => {
            return Promise.reject(error);
        }
    )
}

export const removeUsersFromCC = (body) => (dispatch) => {
    return adminService.removeUsersFromCc(body).then(
        (data) => {
            return Promise.resolve(data);
        },
        (error) => {
            return Promise.reject(error);
        }
    )
}

export const getUsersByCounselerId = (id) => (dispatch) => {
    return adminService.getPdfsByCounselerId(id).then(
        (data) => {
            return Promise.resolve(data);
        },
        (error) => {
            return Promise.reject(error);
        }
    )
}

export const deleteUsers = (id) => {
    return adminService.deleteUser(id).then(
        (data) => {
            return Promise.resolve(data);
        },
        (error) => {
            return Promise.reject(error);
        }
    )
}
const initialState = { 
    allUsers: [],
    loading: false,
    error: null
}

export default function (state = initialState, action){
    const { type } = action;

    switch (type){
        case 'FETCH_USERS_STARTED': 
            return {   
                ...state,
                loading: true,
                error: null
            };
        case 'FETCH_USERS_SUCCESS': 
            return {
                ...state,
                loading: false,
                allUsers: action.users
            }
        case 'FETCH_USERS_FAILURE': 
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state;
    }
}
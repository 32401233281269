import axios from "axios";
import { API_URL } from "./constants";
import axiosInstance from "../interceptor/Interceptor";

const fetchReports = (gallupId, reportId, isBusiness, regenerate = false) => {
	console.info(regenerate)
  const path = isBusiness ? `Gallup/${gallupId}/business_reports` : `Gallup/${gallupId}/school_reports`;
  return axiosInstance
    .get(API_URL + path, {
      headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
      params: {
        num_report: reportId,
        regenerate: regenerate,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export default {
  fetchReports
};
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Main from './components/Main'
import LoginStud from './components/login/LoginStud'
import FAQ from './components/menu/FAQ'
import News from './components/menu/News';
import Dashboard from './components/Dashboard/main/Main'
import Profile from "./components/Dashboard/profile/Profile";
import NewUser from "./components/Dashboard/profile/NewUser";
import SaveProfile from "./components/Dashboard/profile/SaveProfile";
import TestResultList from "./components/Dashboard/test_result/TestResultList";
import SpheresList_New from "./components/Dashboard/sphere/SpheresList_New";
import SpheresList from "./components/Dashboard/sphere/SpheresList";
import Layout from "./components/Layout";
import NewStudent from "./components/Dashboard/profile/NewStudent";
import EditProf from "./components/Dashboard/main/EditProf";
import EditStudent from "./components/Dashboard/main/EditStudent";
import EditSpecialist from "./components/Dashboard/main/EditSpecialist";
import Upload from "./components/Dashboard/profile/Upload";
import ReportForm from './components/Dashboard/report/ReportForm';
import Register from './components/register/Register';
import Admin from './components/admin/Admin';

function App() {
	return (
		<Router>
			<Layout>
				<Routes>
					<Route exact path="/" element={<Main/>}/>
					<Route exact path="/login" element={<LoginStud/>}/>
					<Route exact path="/register" element={<Register />}/>
					<Route exact path="/faq" element={<FAQ/>}/>
					<Route exact path="/news" element={<News/>}/>

					<Route exact path="/dashboard" element={<Dashboard />}/>
					<Route exact path="/admin" element={<Admin />}/>
					<Route exact path="/profile/:gallupId" element={<Profile/>}/>
					<Route exact path="/new_user" element={<NewUser/>}/>
					<Route exact path="/new_student" element={<NewStudent/>}/>
					<Route exact path="/upload/:userId" element={<Upload/>}/>
					<Route exact path="/student/:gallupId/edit"
								 element={<EditStudent/>}/>
					<Route exact path="/specialist/:gallupId/edit"
								 element={<EditSpecialist/>}/>
					<Route exact path="/user/edit" element={<EditProf/>}/>
					<Route exact path="/test_result/:user_id/:section" element={<TestResultList/>}/>
					<Route exact path="/save_profile" element={<SaveProfile/>}/>
					<Route exact path="/spheres_new/:gallupId" element={<SpheresList_New/>}/>
					<Route exact path="/spheres/:gallupId" element={<SpheresList/>}/>
					<Route exact path="/reports/:reportId/:gallupId" element={<ReportForm/>}/>
				</Routes>
			</Layout>
		</Router>
	);
}

export default App;

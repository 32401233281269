import axios from "axios";
import {API_URL} from "../services/constants";

const axiosInstance = axios.create({
	baseURL: API_URL,
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response && error.response.status === 401) {
			localStorage.removeItem('access_token');
			window.location.href = `/login`;
		}
		return Promise.reject();
	}
);

export default axiosInstance;

import axios from "axios";
import {API_URL} from "./constants";
import axiosInstance from "../interceptor/Interceptor";

const edit = (userData) => {
    return axiosInstance
        .patch(API_URL + "auth/users/me", userData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            }
        })
        .then((response) => {
            return response.data;
        });
};

export default {edit};
import React, {useState} from 'react'

import qyzImage from '../../assets/images/qyz.png'
import qyzImageLeft from '../../assets/images/carouselHuman_1.png'
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/solid'
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/outline";
import {Circles} from 'react-loader-spinner'

import '../../assets/css/loginStudent.css'
import LoginMobile from './LoginMobile'
import Container from "../Container";
import {useTranslation} from "react-i18next";
import {login as performLogin} from '../../actions/authActions.js';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";

const LoginStud = () => {
	const {t, i18n} = useTranslation('translation', {keyPrefix: 'login'})
	const [login, setLogin] = useState(''); // Состояние для хранения логина
	const [password, setPassword] = useState(''); // Состояние для хранения пароля
	const [loading, setLoading] = useState(false); // пока не задействовано
	const [switchForm, setSwitchForm] = useState(false);
	const [passwordHidden, setPasswordHidden] = useState(true);
	const dispatch = useDispatch()
	const navigate = useNavigate();
	const errors = useSelector(state => state.auth.error);

	const handleToggle = () => {
		setLogin('')
		setPassword('')
		setSwitchForm(loginVisible => !loginVisible)
	};

	const handleLoginFunction = (e) => {
		// todo поменять стейт загрузки и придумать, как его ресетать, если загрузка уже не идет
		setLoading(true)
		dispatch(performLogin(login, password))
			.then(() => {
				navigate("/dashboard");
			})
			.catch(() => {
				setLoading(false);
			});
		setLoading(false);
	}

	return (
		<div className={`lg:bg-[#EAF8FC]`}>
			{/*<Navbar/>*/}
			<div className="lg:hidden min-h-screen">
				<LoginMobile switchForm={switchForm}/>
			</div>
			<Container>
				<div className={`hidden lg:block xl:px-20 min-h-[calc(100vh-8em)]`}>
					<div className="flex justify-center items-center">
						<div className="w-full">
							<div className={`main-container ${switchForm ? 'active' : ''}`}>
								<div className="form-container sign-up stud select-none">
									<form>
										<div className='flex flex-col mt-5 text-left'>
											<span className='mb-1'>{t('welcome')}</span>
											<span
												className='mb-3 text-[#393DA1] font-[600]'>{t('studentExclamatory')}</span>
											<span className='mb-3 font-bold text-[40px]'>{t('entry')}</span>
										</div>

										<div className='mb-3 flex flex-col items-stretch text-left'>
											<span className='text-[16px]'>{t('enterLogin')}</span>
											<input
												className='mt-2 rounded-[9px] ring-[#ADADAD] ring-1 focus:ring-[#4285F4] py-3 text-[14px] px-4'
												type='text'
												placeholder={t('loginPlaceholder')}
												value={login}
												onChange={(e) => setLogin(e.target.value)}
											/>
										</div>
										<div className='flex flex-col items-stretch text-left'>
											<span className='text-[16px]'>{t('enterPassword')}</span>
											<div className="relative">
												<div className="relative flex rounded-lg">
													<input type={passwordHidden ? 'password' : 'text'}
																 value={password}
																 onChange={(e) => setPassword(e.target.value)}
																 className="text-[14px] mt-2 py-3 px-4 pe-11 w-full rounded-[9px] ring-[#ADADAD] ring-1 focus:ring-[#4285F4]"
																 placeholder={t('passwordPlaceholder')}/>
													<button onClick={(e) => {
														e.preventDefault()
														setPasswordHidden(!passwordHidden);
													}}
																	className="mt-2 absolute inset-y-0 end-0 flex items-center z-20 pe-4">
														<EyeIcon
															className={`size-6 text-[#8E8E8E] ${!passwordHidden && 'hidden'}`}></EyeIcon>
														<EyeSlashIcon
															className={`size-6 text-[#8E8E8E] ${passwordHidden && 'hidden'}`}></EyeSlashIcon>
													</button>
												</div>
											</div>
										</div>
										<div
											className={`${errors ? 'block' : 'hidden'} mt-3 text-[#AD3113] text-[12px]`}>
											{t('errorMessage')}
										</div>
										<div className='text-right my-3'>
											<Link to='/' className='text-[#AD3113] text-[12px]'>{t('forgotPassword')}</Link>
										</div>
										<button
											className='text-white  disabled:cursor-not-allowed bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors rounded-md py-2'
											onClick={handleLoginFunction}
											disabled={loading} // Отключить кнопку во время загрузки
											type='button'
										>
											{loading ?  // Если loading === true, отображаем спиннер вместо текста
												<div className={`flex justify-center items-center`}>
													<Circles
														Circlespe="Puff"
														color="#00BFFF"
														height={20}
														width={20}
														timeout={3000}
													/>
												</div>
												:
												t('submit')
											}
										</button>
										<Link to="/register" className='text-[#393DA1] hover:text-blue-800 underline hover:no-underline transition duration-300 ease-in-out'>{t('registerLink')}</Link>
									</form>
								</div>
								<div className="form-container sign-in prof select-none">
									<form>
										<div className='flex flex-col mt-5 text-left'>
											<span className='mb-1'>{t('welcome')}</span>
											<span
												className='mb-3 text-[#393DA1] font-[600]'>{t('profExclamatory')}</span>
											<span className='mb-3 font-bold text-[40px]'>{t('entry')}</span>
										</div>

										<div className='mb-3 flex flex-col items-stretch text-left'>
											<span className='text-[16px]'>{t('enterLogin')}</span>
											<input
												className='mt-2 rounded-[9px] ring-[#ADADAD] ring-1 focus:ring-[#4285F4] py-3 text-[14px] px-4'
												type='text'
												placeholder={t('loginPlaceholder')}
												value={login}
												onChange={(e) => setLogin(e.target.value)}
											/>
										</div>
										<div className='flex flex-col text-left'>
											<span className='text-[16px]'>{t('enterPassword')}</span>
											<div className="relative">
												<div className="relative flex rounded-lg shadow-sm">
													<input type={passwordHidden ? 'password' : 'text'}
																 value={password}
																 onChange={(e) => setPassword(e.target.value)}
																 className="text-[14px] mt-2 py-3 px-4 pe-11 w-full rounded-[9px] ring-[#ADADAD] ring-1 focus:ring-[#4285F4]"
																 placeholder={t('passwordPlaceholder')}/>
													<button onClick={(e) => {
														e.preventDefault()
														setPasswordHidden(!passwordHidden);
													}}
																	className="mt-2 absolute inset-y-0 end-0 flex items-center z-20 pe-4">
														<EyeIcon
															className={`size-6 text-[#8E8E8E] ${!passwordHidden && 'hidden'}`}></EyeIcon>
														<EyeSlashIcon
															className={`size-6 text-[#8E8E8E] ${passwordHidden && 'hidden'}`}></EyeSlashIcon>
													</button>
												</div>
											</div>
										</div>
										<div
											className={`${errors ? 'block' : 'hidden'} mt-3 text-[#AD3113] text-[12px]`}>
											{t('errorMessage')}
										</div>
										<div className='text-right my-3'>
											<Link to="/" className='text-[#AD3113] text-[12px]'>{t('forgotPassword')}</Link>
										</div>
										<button
											className='text-white disabled:cursor-not-allowed bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors rounded-md py-2'
											onClick={handleLoginFunction}
											disabled={loading} // Отключить кнопку во время загрузки
											type='button'
										>
											{loading ?  // Если loading === true, отображаем спиннер вместо текста
												<div className={`flex justify-center items-center`}>
													<Circles
														Circlespe="Puff"
														color="#00BFFF"
														height={20}
														width={20}
														timeout={3000}
													/>
												</div>
												:
												t('submit')
											}
										</button>
										<Link to="/register" className='text-[#393DA1] hover:text-blue-800 underline hover:no-underline transition duration-300 ease-in-out'>{t('registerLink')}</Link>
									</form>
								</div>
								<div className="toggle-container p-[20px]">
									<div className="toggle select-none rounded-lg overflow-hidden">
										<div
											className="toggle-panel pt-4 px-3.5 toggle-left background-image relative">
											<div
												className={`flex flex-col bg-login-banner text-justify p-4 gap-4`}>
                                            <span
																							className={`text-left text-[24px] font-bold`}>{t('isProf')}</span>
												<span className={`text-left text-[#D7D8EC] font-normal text-sm`}>
                                                    {t('ifProf')}</span>
												<button id="login" onClick={handleToggle}
																className={`flex text-[16px] gap-2 items-center w-fit rounded-full text-[#393DA1] bg-[#FFF] py-2 px-3 font-[600]`}
												>
													<ChevronLeftIcon className="size-6"/>
													<div>{t('toProf')}</div>
												</button>
											</div>
											<img
												className={`pointer-events-none absolute h-1/2 xl:h-3/5 2xl:h-2/3 left-0 bottom-0 object-cover`}
												src={qyzImage}/>
										</div>
										<div
											className="toggle-panel pt-4 px-3.5 toggle-right overflow-hidden background-image relative">
											<div className={`flex flex-col bg-login-banner text-end p-4 gap-2`}>
                                            <span
																							className={`text-[24px] font-bold`}>{t('isNotProf')}</span>
												<span className={`text-[#D7D8EC] font-normal text-sm`}>
                                                    {t('ifNotProf')}</span>
												<div className='flex justify-end'>
													<button id="login" onClick={handleToggle}
																	className={`flex justify-start items-center rounded-full mt-4 text-[#393DA1] bg-[#FFF] p-3 font-[600]`}
													>{t('toStudent')}<ChevronRightIcon
														className="h-6 w-6 text-white-500"/>
													</button>
												</div>
											</div>
											<img
												className={`absolute h-2/3 pointer-events-none 3xl:h-[80%] left-[-15px] bottom-0 object-cover`}
												src={qyzImageLeft}/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default LoginStud;

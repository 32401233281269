import { Button, Modal, notification, Table, Tag } from "antd"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUsersToCc, deleteUsers, fetchUsersList, getUsersByCounselerId, removeUsersFromCC, updateUsersToAdmincc, updateUsersToProforientator, updateUsersToTanuAdmin } from '../../actions/adminActions';
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";
import { deleteFromDashboard, hardDeleteUser } from "../../actions/gallupActions";

const Admin = () => {
    const usersList = useSelector(state => state.admin);
    const profile = useSelector(state => state.profile.userProfile);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [modalUsers, setModalUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedRemoveUsers, setSelectedRemoveUsers] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [modalSelectedRowKeys, setModalSelectedRowKeys] = useState([]);
    const [relateModalSelectedRowKeys, setRelateModalSelectedRowKeys] = useState([]);
    const [students, setStudents] = useState([]);
    const [studentsProforientator, setStudentsProforientator] = useState(null);
    const [changeRoleModal, setChangeRoleModal] = useState(false);
    const [relateProforientatorModal, setRelateProforientatorModal] = useState(false);
    const [deleteUserModal, setDeleteUserModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(fetchUsersList())
    }, [])
    
    useEffect(() => {
        setSelectedUsers(usersList.allUsers.filter((user, index) => selectedRowKeys.includes(index)));
    }, [selectedRowKeys])

    useEffect(() => {
        setSelectedRemoveUsers(modalUsers?.filter((user, index) => relateModalSelectedRowKeys.includes(index)));
    }, [relateModalSelectedRowKeys])

    const adminTanuColumns = [
        { 
            title: 'Имя',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Роль',
            dataIndex: 'role',
            key: 'role',
            filters: [
                {
                    text: 'Тану Админ',
                    value: 'Тану Админ'
                },
                {
                    text: 'Профориентатор',
                    value: 'Профориентатор'
                },
                {
                    text: 'Админ Профориентатор',
                    value: 'Админ Профориентатор'
                }
            ],
            onFilter: (value, record) => record.role == value,
            render: (text) => {
                return (
                    <Tag color={text == 'Тану Админ' ? 'geekblue' : text === 'Профориентатор' ? 'green' : 'volcano'}>
                        { text }
                    </Tag>
                )
            },
        },
        {
            title: 'Номер',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Город',
            dataIndex: 'city',
            key: 'city'
        },
        {
            title: 'Школа',
            dataIndex: 'schools',
            key: 'schools'
        },
        {
            title:'Действие',
            dataIndex: 'actions',
            key: 'actions',
            render: (e, record) => {
                return (
                    <div className="flex items-center gap-5 flex-col">
                        {
                            (record?.role == "Тану Админ" || record?.role == "Админ Профориентатор") && (
                                <Button 
                                    onClick={() => {
                                        if (record.counselors_info.length > 0){
                                            setModalUsers(record.counselors_info);
                                            setSelectedUser(record);
                                        }else{
                                            notification["warning"]({
                                                message: "У этого юзера нету counselors_info"
                                            })
                                        }
                                    }}
                                    color="primary"
                                    variant="solid"
                                > 
                                    Показать профориентаторов
                                </Button>  
                            ) 
                        }
                        <Button 
                            onClick={() => {
                                setLoading(true);
                                dispatch(getUsersByCounselerId(record._id)).then((res) => {
                                    if (res.length == 0){
                                        notification["warning"]({
                                            message: "У этого профориентатора нету студентов"
                                        })
                                    }else if (res){
                                        setStudents(res);
                                        setStudentsProforientator(record?.name || record?.email);
                                    } 
                                    setLoading(false);
                                }).catch((err) => {
                                    notification["error"]({
                                        message: err
                                    })
                                    setLoading(false);
                                })
                            }}
                            color="danger"
                            variant="solid"
                        >
                            Посмотреть студентов
                        </Button>
                    </div>
                )
            }
        }
    ]

    const adminColumns = [
        {
            title: "Имя",
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: "Email",
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: "Школа",
            dataIndex: 'schools',
            key: 'schools'
        },
        {
            title: 'Действие',
            dataIndex: 'buttons',
            key: 'buttons',
            render: (record, item) => {
                return (
                    <div>
                        <Button 
                            onClick={() => {
                                setLoading(true);
                                dispatch(getUsersByCounselerId(item.counselor_id)).then((res) => {
                                    if (res.length == 0){
                                        notification["warning"]({
                                            message: "У этого профориентатора нету студентов"
                                        })
                                    }else if (res){
                                        setStudents(res);
                                        setStudentsProforientator(item?.name || item?.email);
                                    } 
                                    setLoading(false);
                                })
                            }}
                            color="danger"
                            variant="solid"
                        >
                            Посмотреть студентов
                        </Button>
                    </div>
                )
            }
        }
    ]

    const studentsColumns = [
        {
            title: 'Имя',
            dataIndex: 'Name',
            key: 'Name'
        },
        {
            title: 'Школа',
            dataIndex: 'School',
            key: 'School'
        },
        {
            title: 'Класс',
            dataIndex: 'Grade',
            key: 'Grade'
        },
        {
            title: 'Действие',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => {
                return (
                    <div className="flex flex-col gap-3">
                        <Button 
                            onClick={() => navigate(`/profile/${record._id}`)}
                            color="primary"
                            variant="solid"
                        > 
                            Посмотреть информацию
                        </Button>
                        <Button 
                            onClick={() => { setDeleteUserModal(true); setSelectedUser(record); }} 
                            color="danger"
                            variant="solid"
                        > 
                            Удаление 
                        </Button>
                    </div>
                )
            }
        }
    ]

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys) => {
          setSelectedRowKeys(selectedKeys);
        },
    };

    const relateModalRowSelection = {
        relateModalSelectedRowKeys,
        onChange: (relateModalSelectedRowKeys) => {
            setRelateModalSelectedRowKeys(relateModalSelectedRowKeys);
        },
    }

    const modalRowSelection = {
        type: 'radio', 
        modalSelectedRowKeys,
        onChange: (modalSelectedRowKeys) => {
            setModalSelectedRowKeys(modalSelectedRowKeys);
        },
    }

    const roleModalHandler = () => {
        if (selectedRowKeys.length == 0){
            notification["warning"]({
                message: "Надо выбрать юзера"
            })
        }else{
            setChangeRoleModal(true);
        }
    }

    const appointHandler = () => {
        if (selectedRowKeys.length == 0){
            notification["warning"]({
                message: "Надо выбрать юзера"
            })
        }else if (usersList?.allUsers?.filter(user => user.role == 'Админ Профориентатор').length == 0){
            notification["warning"]({
                message: "Нету Админ Профориентаторов"
            })
        }else{ 
            setRelateProforientatorModal(true);
        }
    }

    const changeRoleToAdminccHandler = () => {
        setLoading(true);

        dispatch(updateUsersToAdmincc({ user_ids: selectedUsers.map(user => user._id) })).then((res) => {
            if (res){
                notification["success"]({
                    message: res
                });
                setChangeRoleModal(false);
                resetUsersHandler();
                dispatch(fetchUsersList());
            }
            setLoading(false);
        })
    }

    const changeRoleToTanuAdminHandler = () => {
        setLoading(true);

        dispatch(updateUsersToTanuAdmin({ user_ids: selectedUsers.map(user => user._id) })).then((res) => {
            if (res){
                notification["success"]({
                    message: res
                });
                setChangeRoleModal(false)
                resetUsersHandler();
                dispatch(fetchUsersList());
            }
            setLoading(false);
        })
    }

    const changeRoleToProforientator = () => {
        setLoading(true);

        dispatch(updateUsersToProforientator({ user_ids: selectedUsers.map(user => user._id) })).then((res) => {
            if (res){
                notification["success"]({
                    message: res
                });
                setChangeRoleModal(false)
                resetUsersHandler();
                dispatch(fetchUsersList());
            }
            setLoading(false);
        })
    }

    const addRelateToAdminccHandler = () => {
        setLoading(true);

        dispatch(addUsersToCc({ user_ids: selectedUsers.map(user => user._id), cc_admin_id: usersList?.allUsers?.filter(user => user.role == 'Админ Профориентатор')[modalSelectedRowKeys[0]]._id })).then((res) => {
            if (res){
                notification["success"]({
                    message: res
                });
                setRelateProforientatorModal(false);
                resetUsersHandler();
                dispatch(fetchUsersList());
                
            }
            setLoading(false);
        })
    }

    const removeFromAdminHandler = () => {
        setLoading(true);

        dispatch(removeUsersFromCC({ user_ids: selectedRemoveUsers?.map(user => user.counselor_id ), cc_admin_id: selectedUser?._id })).then((res) => {
            if (res){
                notification["success"]({
                    message: res
                })
                dispatch(fetchUsersList());
            }
            setLoading(false);
        })
    }

    const deleteUserHandler = (type) => {
        setLoading(true);

        if (type == 'soft'){
            dispatch(deleteFromDashboard(selectedUser?._id)).then((res) => {
                if (res == 200){
                    notification["success"]({
                        message: 'Успешно сделали soft delete'
                    })
                }
                setDeleteUserModal(false);
                resetUsersHandler();
                setSelectedUser(null);
                setLoading(false);
            })
        }else if(type == 'hard'){
            dispatch(hardDeleteUser(selectedUser?._id)).then((res) => {
                if (res == 200){
                    notification["success"]({
                        message: 'Успешно сделали hard delete'
                    })
                }
                setDeleteUserModal(false);
                setSelectedUser(null);
                resetUsersHandler();
                setLoading(false);
            })
        }
    }

    const resetUsersHandler = () => {
        setModalUsers([]);
        setSelectedUser(null);
        setSelectedUsers([]);
        setSelectedRowKeys([]);
        setModalSelectedRowKeys([]);
        setRelateModalSelectedRowKeys([]);
        setStudents([]);
    }

    const deleteUsersHandler = () => {
        if (selectedUsers.length > 0){
            setLoading(true);

            deleteUsers(selectedUsers.map(user => user?._id)).then((res) => {
                if (res){
                    setLoading(false);
                    resetUsersHandler();
                    dispatch(fetchUsersList())
                    notification["success"]({
                        message: "Успешно удалили юзера"
                    })
                }
            })
        } else {
            notification["warning"]({
                message: "Надо выбрать юзера"
            })
        }
        
    }


    return (
        <div className="mb-3 container-dashboard mx-auto" style={{ minHeight: 'calc(100vh - 130px - 326px)'}}>
            <Loading isLoading={usersList.loading || loading} />
            <div className="flex justify-between items-center">
                <div className="my-6 text-4xl font-bold">
                    Все пользователи
                </div>
                {
                    profile?.role == 'Тану Админ' && (
                        <div className="flex gap-3 items-center">
                            <div className="flex w-fit items-center rounded-full justify-center px-6 py-2 h-fit font-bold text-white bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors gap-2 text-[16px] cursor-pointer" onClick={roleModalHandler}>
                                Поменять роль
                            </div>
                            <div className="flex w-fit items-center rounded-full justify-center px-6 py-2 h-fit font-bold text-white bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors gap-2 text-[16px] cursor-pointer" onClick={appointHandler}> 
                                Назначить на кого-то
                            </div>
                            <div className="flex w-fit items-center rounded-full justify-center px-6 py-2 h-fit font-bold text-white bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors gap-2 text-[16px] cursor-pointer" onClick={deleteUsersHandler}> 
                                Удалить пользователей
                            </div>
                        </div>        
                    )
                }
            </div>
            {
                modalUsers.length > 0 ? 
                <Modal
                    title={``}
                    open={modalUsers.length > 0}
                    width={`fit-content`}
                    footer={null}
                    onCancel={() => {setModalUsers([]); resetUsersHandler(); }}
                >
                    <div className="pt-6">
                        <div className="my-6 text-xl text-center">
                            Профориентаторы <span className="font-bold">{ selectedUser?.name || selectedUser?.email }</span>
                        </div>
                        <Table
                            columns={adminColumns}
                            dataSource={modalUsers?.map((user, index) => { return { ...user, key: index }})}
                            pagination={false}
                            rowSelection={relateModalRowSelection}
                        />
                        <div className="flex gap-3 items-center justify-center mt-6">
                            <div className="flex w-fit items-center rounded-full justify-center px-6 py-2 h-fit font-bold text-white bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors gap-2 text-[16px] cursor-pointer" onClick={removeFromAdminHandler}>
                                Исключить профориентатора от { selectedUser?.name || selectedUser?.email }
                            </div>
                        </div>        
                    </div>
                </Modal> 
                : ''
            }
            {
                changeRoleModal ? 
                <Modal
                    title={``}
                    footer={null}
                    open={changeRoleModal}
                    width={`fit-content`}
                    onCancel={() => { setChangeRoleModal(false); resetUsersHandler()}}
                >
                    <div className="pt-6">
                        <div className="my-6 text-xl text-center font-bold">
                            Поменять роль
                        </div>
                        <Table 
                            columns={adminTanuColumns}
                            dataSource={selectedUsers.map((user, index) => {return {...user, key: index}})}
                            pagination={false}
                            className="mb-[64px]"
                        />
                        <div className="flex justify-center gap-3">
                            <div className="flex w-fit items-center rounded-full justify-center px-6 py-2 h-fit font-bold text-white bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors gap-2 text-[16px] cursor-pointer" onClick={changeRoleToTanuAdminHandler}>
                                Поменять роль на Тану Админ
                            </div>
                            <div className="flex w-fit items-center rounded-full justify-center px-6 py-2 h-fit font-bold text-white bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors gap-2 text-[16px] cursor-pointer" onClick={changeRoleToAdminccHandler}> 
                                Поменять роль на Админ Профориентатор
                            </div>
                            <div className="flex w-fit items-center rounded-full justify-center px-6 py-2 h-fit font-bold text-white bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors gap-2 text-[16px] cursor-pointer" onClick={changeRoleToProforientator}> 
                                Поменять роль на Профориентатор
                            </div>
                        </div>
                    </div>
                </Modal>
                : ''    
            }   
            {
                relateProforientatorModal ?
                <Modal 
                    title={``}
                    footer={null}
                    open={relateProforientatorModal}
                    width={`fit-content`}
                    onCancel={() => { setRelateProforientatorModal(false); resetUsersHandler(); }}
                >
                    <div className="pt-6">
                        <div className="my-6 text-xl text-center font-bold">
                            Назначить на кого то
                        </div>
                        <Table 
                            columns={profile?.role == 'Тану Админ' ? adminTanuColumns : adminColumns}
                            dataSource={usersList?.allUsers?.filter(user => user.role == 'Админ Профориентатор').map((user, index) => {return {...user, key: index}})}
                            pagination={false}
                            className="mb-[64px]"
                            rowSelection={profile?.role == 'Тану Админ' ? modalRowSelection : null}
                        />
                        <div className="flex justify-center gap-3">
                            <div className="flex w-fit items-center rounded-full justify-center px-6 py-2 h-fit font-bold text-white bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors gap-2 text-[16px] cursor-pointer" onClick={addRelateToAdminccHandler}>
                                Назначить
                            </div>
                        </div>
                    </div>
                </Modal>
                : ''                
            }
            {
                students.length > 0 ? 
                <Modal
                    title={``}
                    footer={null}
                    open={students.length > 0}
                    width={`fit-content`}
                    onCancel={() => setStudents([])}
                >
                    <div className="pt-6">
                        <div className="my-6 text-xl text-center font-bold">
                            Ученики {studentsProforientator}
                        </div>
                        <Table 
                            columns={studentsColumns}
                            dataSource={ students }
                            className="mb-[64px]"
                        />
                        <div className="flex justify-center gap-3">
                            <div className="flex w-fit items-center rounded-full justify-center px-6 py-2 h-fit font-bold text-white bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors gap-2 text-[16px] cursor-pointer" onClick={addRelateToAdminccHandler}>
                                Назначить
                            </div>
                        </div>
                    </div>
                </Modal>
                : ''
            }
            {
                deleteUserModal && (
                    <Modal
                        title={``}
                        footer={null}
                        open={deleteUserModal}
                        width={`fit-content`}
                        onCancel={() => {setDeleteUserModal(false); resetUsersHandler(); }}
                    >
                        <div className="pt-6">
                            <div className="my-6 text-xl text-center font-bold">
                                Удаление аккаунта { selectedUser?.Name }
                            </div>
                            <div className="flex gap-3">
                                <Button 
                                    className="w-full" 
                                    onClick={() => deleteUserHandler("soft")}
                                    color="danger"
                                    variant="solid"
                                >
                                    Soft Delete
                                </Button>
                                <Button 
                                    className="w-full" 
                                    onClick={() => deleteUserHandler("hard")}
                                    color="danger"
                                    variant="solid"
                                >
                                    Hard Delete
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )
            }
            <Table
                columns={profile?.role == 'Тану Админ' ? adminTanuColumns : adminColumns}
                dataSource={profile?.role == 'Тану Админ' ? usersList.allUsers.map((user, index) => {return {...user, key: index}}) : usersList.allUsers}
                rowSelection={profile?.role == 'Тану Админ' ? rowSelection : null}
            />
        </div>
    )
}

export default Admin
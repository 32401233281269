import axios from "axios";
import axiosInstance from "../interceptor/Interceptor"
import { API_URL } from "./constants"

const fetchUsers = () => {
    return axiosInstance
        .patch(API_URL + "auth/users/all", '', {
            headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        })
}

const updateUsersToAdmincc = (body) => {
    return axiosInstance
        .patch(API_URL + "auth/users/cc-admin", body, {
            headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}
        }).then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        })
}

const updateUsersToTanuAdmin = (body) => {
    return axiosInstance
        .patch(API_URL + "auth/users/tanu-admin", body, {
            headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}
        }).then((response) => {
            return response.data
        })
        .catch((error) => {
            return error;
        })
}

const updateUsersToProforientator = (body) => {
    return axiosInstance
        .patch(API_URL + "auth/users/proforientator", body, {
            headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}
        }).then((response) => {
            return response.data
        })
        .catch((error) => {
            return error;
        })
}

const addUsersToCc = (body) => {
    return axiosInstance
        .patch(API_URL + "auth/users/proforientator/cc", body, {
            headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}
        }).then((response) => {
            return response.data
        })
        .catch((error) => {
            return error;
        })
}

const removeUsersFromCc = (body) => {
    return axiosInstance
        .patch(API_URL + "auth/users/proforientator/cc/remove", body, {
            headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}
        }).then((response) => {
            return response.data
        })
        .catch((error) => {
            return error;
        })
}

const getPdfsByCounselerId = (counselorId) => {
    return axiosInstance
        .get(API_URL + `Gallup/${counselorId}/by_counselor_id`, {
            headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}
        }).then((response) => {
            return response.data
        })
        .catch((error) => {
            return error;
        })
}

export const deleteUser = (body) => {
    return axiosInstance
        .delete(API_URL + `auth/users`, {
            headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`},
            data: body
        }, ).then((response) => {
            return response.data
        })
        .catch((error) => {
            return error;
        })
}

export default { 
    fetchUsers,
    updateUsersToAdmincc,
    updateUsersToTanuAdmin,
    updateUsersToProforientator,
    addUsersToCc,
    removeUsersFromCc,
    getPdfsByCounselerId,
    deleteUser
}

import {combineReducers} from 'redux';
import authReducer from './authReducer';
import profileReducer from './profileReducer';
import gallupReducer from './gallupReducer';
import newUserReducer from "./newUserReducer";
import newStudentReducer from "./newStudentReducer";
import editProfReducer from "./editProfReducer";
import reportReducer from "./reportReducer";
import spheresReducer from "./spheresReducer";
import testResultReducer from './testResultReducer';
import adminReducer from './adminReducer';


const rootReducer = combineReducers({
	auth: authReducer,
	profile: profileReducer,
	gallup: gallupReducer,
	newUser: newUserReducer,
	newStudent: newStudentReducer,
	editProf: editProfReducer,
	report: reportReducer,
	sphere: spheresReducer,
	testResult: testResultReducer,
	admin: adminReducer
});

export default rootReducer;

import React, {useState} from 'react'
import {Circles} from 'react-loader-spinner'
import '../../assets/css/loginStudent.css'
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/outline";
import Container from "../Container";
import {handleLogin} from "./LoginFunction";
import {useTranslation} from "react-i18next";
import {login as performLogin} from '../../actions/authActions.js';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";


const LoginProfMobile = () => {
	const {t, i18n} = useTranslation('translation', {keyPrefix: 'login'})
	const [login, setLogin] = useState(''); // Состояние для хранения логина
	const [password, setPassword] = useState(''); // Состояние для хранения пароля
	const [loading, setLoading] = useState(false); // пока не задействовано
	const [passwordHidden, setPasswordHidden] = useState(true);

	const dispatch = useDispatch()
	const navigate = useNavigate();
	const errors = useSelector(state => state.auth.error);

	const handleLoginFunction = (e) => {
		// todo поменять стейт загрузки и придумать, как его ресетать, если загрузка уже не идет
		setLoading(true)
		dispatch(performLogin(login, password))
			.then(() => {
				navigate("/dashboard");
			})
			.catch(() => {
				setLoading(false);
			});
		setLoading(false);
	}

	return (
		<Container>
			<div className='flex flex-col py-5'>
				<form>
					<div className='flex flex-col text-left'>
						<span className=''>{t('welcome')}</span>
						<span className='mb-3 text-[#393DA1] font-[600]'>{t('profExclamatory')}</span>
					</div>

					<div className='my-3 flex flex-col items-stretch text-left'>
						<span className='text-[16px]'>{t('enterLogin')}</span>
						<input
							className='mt-2 py-3 px-4 text-[14px] rounded-[9px] ring-[#ADADAD] ring-1 focus:ring-[#4285F4] border-none outline-none'
							type='text'
							placeholder={t('loginPlaceholder')}
							value={login}
							onChange={(e) => setLogin(e.target.value)}
						/>
					</div>
					<div className='flex flex-col items-stretch text-left'>
						<span className='text-[16px]'>{t('enterPassword')}</span>
						<div className="relative">
							<div className="relative flex rounded-lg shadow-sm">
								<input type={passwordHidden ? 'password' : 'text'}
											 value={password}
											 onChange={(e) => setPassword(e.target.value)}
											 className="text-[14px] mt-2 py-3 px-4 pe-11 w-full rounded-[9px] ring-[#ADADAD] ring-1 focus:ring-[#4285F4] border-none outline-none"
											 placeholder={t('passwordPlaceholder')}/>
								<button onClick={(e) => {
									e.preventDefault()
									setPasswordHidden(!passwordHidden);
								}}
												className="mt-2 absolute inset-y-0 end-0 flex items-center z-20 pe-4">
									<EyeIcon
										className={`size-6 text-[#8E8E8E] ${!passwordHidden && 'hidden'}`}></EyeIcon>
									<EyeSlashIcon
										className={`size-6 text-[#8E8E8E] ${passwordHidden && 'hidden'}`}></EyeSlashIcon>
								</button>
							</div>
						</div>
					</div>
					<div
						className={`${errors ? 'block' : 'hidden'} mt-3 text-[#AD3113] text-[12px]`}>
						{t('errorMessage')}
					</div>
					<div className='text-right my-3'>
						<Link to="/" className='text-[#AD3113] text-[12px]'>{t('forgotPassword')}</Link>
					</div>
					<div className='flex justify-between w-full mb-3'>
						<button
							className='w-full text-white disabled:cursor-not-allowed bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors rounded-md py-2'
							onClick={handleLoginFunction}
							type='button'
							disabled={loading} 
						>
							{loading ? 
								<div className={`flex justify-center items-center`}>
									<Circles
										Circlespe="Puff"
										color="#00BFFF"
										height={20}
										width={20}
										timeout={3000}
									/>
								</div>
								:
								t('submit')
							}
						</button>
					</div>
					<Link to="/register" className='text-[#393DA1] hover:text-blue-800 underline hover:no-underline transition duration-300 ease-in-out'>{t('registerLink')}</Link>
				</form>
			</div>
		</Container>
	);
};

export default LoginProfMobile;

import axios from "axios";
import {API_URL} from "./constants";
import axiosInstance from "../interceptor/Interceptor";

const fetchUserProfile = () => {
	return axiosInstance
		.get(API_URL + "auth/users/me", {
			headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}
		})
		.then((response) => {
			return response.data;
		});
};

const deleteUser = (gallupId) => {
	return axiosInstance
		.delete(API_URL + `Gallup/${gallupId}`, {
			headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}
		})
		.then((response) => {
			return response.data;
		});
};

export default {
	fetchUserProfile,
	deleteUser
};

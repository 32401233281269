import axios from "axios";
import {API_URL} from "./constants";
import axiosInstance from "../interceptor/Interceptor";

const register = (userData) => {
	return axiosInstance
		.post(API_URL + "Gallup/info", userData, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
				"Content-Type": "multipart/form-data"
			}
		})
		.then((response) => {
			return response.data;
		});
};

export default {register};
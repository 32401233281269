import React, {useEffect, useRef, useState} from 'react';
import '../../../assets/css/main.css';
import {ChevronDownIcon, ChevronUpIcon, XMarkIcon} from '@heroicons/react/24/solid';
import {Link, useNavigate} from 'react-router-dom';
import {validateForm} from "./ProfileValidators";
import Container from "../../Container";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {registerUser} from "../../../actions/newUserActions"

const NewUser = () => {
	const {t, i18n} = useTranslation('translation', {keyPrefix: 'dashboard.profileForm'})
	const dispatch = useDispatch()
	const navigate = useNavigate();

	const [sphereSelectOpened, setSphereSelectOpened] = useState(false);
	const [typeSelectOpened, setTypeSelectOpened] = useState(false);

	const [company, setCompany] = useState('');
	const [companyDirty, setCompanyDirty] = useState(false);

	const [profession, setProfession] = useState('');
	const [professionDirty, setProfessionDirty] = useState(false);

	const [name, setName] = useState('');
	const [nameDirty, setNameDirty] = useState(false);

	const [photo, setPhoto] = useState(null); // Add this line for the photo state

	const types = [
		'Professional',
		'High School Student',
		'University Student'
	];
	const [type, setType] = useState(types[0]);

	const [expertiseRequired, setExpertiseRequired] = useState('');
	const [expertiseRequiredDirty, setExpertiseRequiredDirty] = useState(false);

	const [info, setInfo] = useState('');
	const [infoDirty, setInfoDirty] = useState(false);

	const [spheres, setSpheres] = useState({
		"Бизнес и управление": 1,
        "Здравоохранение": 1,
        "Образование": 1,
        "Естественные науки, математика и статистика": 1,
        "Инженерия": 1,
        "ИТ": 1,
        "Социальные науки": 1,
        "Гуманитарные науки": 1,
        "Искусство": 1,
        "Услуги": 1,
        "Сельское хозяйство и биоресурсы": 1,
        "Национальная оборона и безопасность": 1
	});
	const changeSphereValue = (eValue, key) => {
		let newSpheres = {...spheres}
		if (eValue === '') {
			newSpheres[key] = 1
			setSpheres(newSpheres)
			return
		}
		if (eValue.length === 2 && eValue[0] === '1') {
			newSpheres[key] = Number(eValue[1])
			setSpheres(newSpheres)
		}
		if (parseInt(eValue) >= 0 && parseInt(eValue) <= 10) {
			newSpheres[key] = parseInt(eValue)
			setSpheres(newSpheres)
		}
	}

	useEffect(() => {
		if (!sphereSelectOpened) {
			window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		}
	}, [sphereSelectOpened]);

	const [fieldErrors, setFieldErrors] = useState({
		company: [],
		profession: [],
		name: [],
		expertiseRequired: [],
		info: []
	});

	const validateFields = () => {
		let errors = validateForm(company, name, expertiseRequired, info);
		setFieldErrors(errors)
		let numberOfErrors = 0
		Object.values(errors).forEach(errorArray => numberOfErrors += errorArray.length);
		return numberOfErrors
	}
	useEffect(() => {
		validateFields()
	}, [company, name, expertiseRequired, info]);

	const handleSubmit = () => {
		setCompanyDirty(true);
		setProfessionDirty(true);
		setNameDirty(true);
		setExpertiseRequiredDirty(true);
		setInfoDirty(true);
	
		const numberOfErrors = validateFields();
		if (numberOfErrors === 0) {
			const formData = new FormData();
			formData.append('company', company);
			formData.append('profession', profession);
			formData.append('name', name);
			formData.append('person_type', type);
			formData.append('expertise_required', expertiseRequired);
			formData.append('info', info);
			formData.append('proInput', JSON.stringify(spheres));
			if (photo) {
				formData.append('photo', photo); // Add this line to append the photo
			}
	
			dispatch(registerUser(formData))
				.then((result) => {
					navigate(`/upload/${result._id}`);
				})
				.catch((err) => {
				});
		}
	};

	return (
		<div className={`bg-[#eaf8fc]  min-h-screen`}>
			<Container>
				<div
					className={`pb-4 rounded-[15px] lg:max-w-[40em] w-full select-none mx-auto`}>
					<div
						className={`no-scrollbar bg-white relative rounded-[15px] gap-4 shadow-md flex flex-col md:p-6 p-3 text-left`}>
						<span className={`font-bold text-[24px] md:text-[32px] text-center`}>{t('about')}</span>
						<form className={`flex flex-col gap-4`} autoComplete="off">
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>{t('company')}</label>
								<input aria-autocomplete="list" type="text" id={``} placeholder='Введите название компании'
											 value={company}
											 onChange={(evt) => {
												 setTimeout(() => setCompanyDirty(true), 100)
												 setCompany(evt.target.value)
											 }}
											 className={`shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]`}/>
								<ul
									className={`${companyDirty && fieldErrors.company.length > 0 ? 'block' : 'hidden'} flex flex-col gap-1 text-red-600 text-[14px]`}>
									{fieldErrors.company.map((error, idx) => (
										<li key={idx}>{error}</li>
									))}
								</ul>
							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>{t('photo')}</label>
								<div className="flex flex-row gap-4 justify-between items-center w-full">
									<input aria-autocomplete="list"
										accept="image/*"
										type="file"
										onChange={e => setPhoto(e.target.files[0])}
										className="shadow-none outline-none focus:border-[#4285F4] font-light px-4 py-3 rounded-[9px] border border-[#ADADAD] w-full"/>
									{photo &&
										<div
											className="size-[3em] min-h-[3em] min-w-[3em] rounded-full overflow-hidden ring-[#ADADAD] ring-1">
											<img src={photo ? URL.createObjectURL(photo) : null} alt="" className="size-full object-cover"/>
										</div>
									}
								</div>
							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>{t('profession')}</label>
								<input aria-autocomplete="list" type="text" placeholder='Введите профессию'
											value={profession}
											onChange={(evt) => {
												setTimeout(() => setProfessionDirty(true), 100);
												setProfession(evt.target.value);
											}}
											className={`shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]`}/>
								{/* <ul
									className={`${professionDirty && fieldErrors.profession.length > 0 ? 'block' : 'hidden'} flex flex-col gap-1 text-red-600 text-[14px]`}>
									{fieldErrors.profession.map((error, idx) => (
										<li key={idx}>{error}</li>
									))}
								</ul> */}
							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>{t('name')}</label>
								<input aria-autocomplete="list" type="text" id={``} placeholder='Введите имя'
											 value={name}
											 onChange={(evt) => {
												 setTimeout(() => setNameDirty(true), 100)
												 setName(evt.target.value)
											 }}
											 className={` shadow-none outline-none focus:border-[#4285F4]  placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]`}/>
								<ul
									className={`${nameDirty && fieldErrors.name.length > 0 ? 'block' : 'hidden'} flex flex-col gap-1 text-red-600 text-[14px]`}>
									{fieldErrors.name.map((error, idx) => (
										<li key={idx}>{error}</li>
									))}
								</ul>
							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>{t('type')}</label>
								<div
									onClick={() => setTypeSelectOpened(!typeSelectOpened)}
									className={`h-full focus:border-[#4285F4] font-light rounded-[9px] border border-[#ADADAD] cursor-pointer flex flex-col items-center`}>
									<div className={`px-4 py-3 flex flex-row justify-between w-full`}>
										<span className={`${typeSelectOpened && 'text-[#B6B6B6]'}`}>{type}</span>
										<ChevronDownIcon
											className={`text-[#767676] size-6 transition-transform  ${typeSelectOpened && 'rotate-180'}`}/>
									</div>
									<div
										className={`flex-col w-full rounded-b-[9px] ${typeSelectOpened ? 'flex' : 'hidden'}`}>
										{types.map((type, key) => (
											<span
												className={`last:rounded-b-[9px] block px-4 py-3 duration-100 hover:border-l-2 active:border-l-4 md:hover:border-l-4 md:active:border-l-6 border-[#1AAD7F] transition-all ease-in`}
												key={key} onClick={() => setType(type)}>{type}</span>
										))}
									</div>
								</div>
							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>{t('expertiseRequired')}</label>
								<input aria-autocomplete="list" type="text" id={``} placeholder='Введите требуемую экспертизу'
											 value={expertiseRequired}
											 onChange={(evt) => {
												 setTimeout(() => setExpertiseRequiredDirty(true), 100)
												 setExpertiseRequired(evt.target.value)
											 }}
											 className={`shadow-none outline-none focus:border-[#4285F4]  placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]`}/>
								<ul
									className={`${expertiseRequiredDirty && fieldErrors.expertiseRequired.length > 0 ? 'block' : 'hidden'} flex flex-col gap-1 text-red-600 text-[14px]`}>
									{fieldErrors.expertiseRequired.map((error, idx) => (
										<li key={idx}>{error}</li>
									))}
								</ul>
							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>{t('Info')}</label>
								<textarea aria-autocomplete="list" rows={4} id={``} placeholder='Введите информацию'
													value={info}
													onChange={(evt) => {
														setTimeout(() => setInfoDirty(true), 100)
														setInfo(evt.target.value)
													}}
													className={`resize-none shadow-none outline-none focus:border-[#4285F4]  placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]`}/>
								<ul
									className={`${infoDirty && fieldErrors.info.length > 0 ? 'block' : 'hidden'} flex flex-col gap-1 text-red-600 text-[14px]`}>
									{fieldErrors.info.map((error, idx) => (
										<li key={idx}>{error}</li>
									))}
								</ul>
							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>{t('evaluate')}</label>
								<div
									className={`h-full focus:border-[#4285F4] font-light rounded-[9px] border border-[#ADADAD] flex flex-col items-center`}>
									<div
										className={`px-4 py-3 flex flex-row justify-between w-full cursor-pointer rounded-[9px] text-[#767676]
										${sphereSelectOpened ? 'bg-white' : 'bg-[#F7F7F7]'}`}
										onClick={() => setSphereSelectOpened(!sphereSelectOpened)}>
										<span>{t('showSpheres')}</span>
										<ChevronDownIcon
											className={`text-[#767676] size-6 transition-transform  ${sphereSelectOpened && 'rotate-180'}`}/>
									</div>
									<div
										className={`flex-col gap-2 w-full py-2 ${sphereSelectOpened ? 'flex' : 'hidden'}`}>
										{Object.keys(spheres).map((sphereKey, idx) => (
											<div
												className={`px-4 flex flex-row justify-between items-center`}
												key={idx}>
												<div>{sphereKey}</div>
												<input type="text"
															 className={`outline-none max-w-[4em] px-[18px] py-[10px] ring-[#B9B9B9] ring-[1px] rounded-[5px]
															 text-[#767676] text-[16px] font-[500] text-center`}
															 placeholder={'0-10'}
															 value={Object.values(spheres)[idx]}
															 onChange={e => changeSphereValue(e.target.value, sphereKey)}/>
											</div>
										))}
									</div>
								</div>
							</div>
						</form>
						<button
							onClick={handleSubmit}
							className={`font-bold text-[16px] bg-[#1AAD7F] hover:bg-[#1AAD7F]/80 transition-colors w-full rounded-[10px] text-white py-3`}>
							{t('save')}
						</button>
					</div>
				</div>
			</Container>
		</div>
	);
	}

export default NewUser;
